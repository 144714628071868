<template>
  <div>
    <vue-simple-suggest
      v-model="chosen"
      value-attribute="id"
      display-attribute="label"
      :list="populateLookupData"
      :max-suggestions="10"
      :min-length="1"
      :debounce="500"
      :filter-by-query="false"
      mode="select"
      @select="onSuggestSelect"
      @input="onInputEvent"
      @focus="onInputFocus"
      @blur="onInputBlur"
    >
      <input
        v-show="inputShown"
        :id="pickerId"
        ref="suggestInput"
        v-model="text"
        :name="pickerName"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :readonly="readonly"
        :aria-required="required"
        :title="title"
        type="search"
        autocomplete="off"
      />
      <span v-if="shownLabel">
        {{ chosen.label }}
      </span>

      <input
        :id="inputId"
        :value="fieldValue"
        :name="inputName"
        type="hidden"
      />
      <p v-if="debug">
        <strong>Hidden Input:</strong> {{ inputName }}={{ fieldValue }}<br />
        <strong>Initial Value:</strong> {{ inputValue }}
      </p>

      <div
        v-if="loading"
        slot="misc-item-below"
        class="misc-item"
      >
        <span>Working on it&hellip;</span>
      </div>
    </vue-simple-suggest>
  </div>
</template>

<script>
import 'vue-simple-suggest/dist/styles.css';
import BasePicker from './base_picker';

export default BasePicker.extend({
  props: {
    // Required. ID to assign hidden input. Visible input is suffixed with _picker.
    inputId: {
      type: String,
      default: 'office_picker_no_id',
    },
    // Required. Name to assign hidden input. Visible input is suffixed with _picker.
    inputName: {
      type: String,
      default: 'office_picker_no_name',
    },
    // Optional ('id'). This picker only supports:
    // * id - Stores the underlying database identifier.
    valueMode: {
      validator(val) {
        return ['id'].includes(val);
      },
      default: 'id',
    },
    // Optional (false). Controls display of debug information.
    debug: {
      type: Boolean,
      default: false,
    },
    // Optional ('id'). Controls how selection is mapped to the entry box.
    textMapping: {
      type: String,
      default: 'id',
    },
  },

  watch: {
    chosen(val) {
      const {
        labelExternalId,
        fieldValue,
      } = this;

      this.setExternalText(labelExternalId, 'labelExternalId', 'label', val);
      this.$emit('change', fieldValue);
    },
  },

  methods: {
    apiUrl() {
      return '/lookup/office_lookups.json';
    },
  },
});
</script>
