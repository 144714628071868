<template>
  <div>
    <vue-simple-suggest
      v-model="chosen"
      value-attribute="key"
      display-attribute="display"
      class="field-hint--search"
      :list="populateLookupData"
      :max-suggestions="25"
      :min-length="1"
      :debounce="500"
      :filter-by-query="false"
      mode="select"
      @select="onSuggestSelect"
      @input="onInputEvent"
      @focus="onInputFocus"
      @blur="onInputBlur"
    >
      <input
        v-show="inputShown"
        :id="pickerId"
        ref="suggestInput"
        v-model="text"
        :aria-describedby="pickerId + '-onet-hint ' + pickerId + '-onet-details'"
        :name="pickerName"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :readonly="readonly"
        :aria-required="required"
        :title="title"
        type="text"
        autocomplete="off"
      />
      <div
        v-if="loading"
        slot="misc-item-below"
        class="misc-item"
      >
        <span>Working on it&hellip;</span>
      </div>
      <div
        slot="suggestion-item"
        slot-scope="{ suggestion }"
      >
        <span class="vue-simple-picker-label">{{ suggestion.label }}</span>
      </div>
    </vue-simple-suggest>
    <div
      v-show="inputShown"
      :id="pickerId + '-onet-hint'"
      class="hint"
    >
      e.g., job title, company, occupation keyword, or
      <a
        href="https://www.onetonline.org"
        title="Opens in a new window"
        data-tooltip="true"
        target="_blank"
      >
        O*NET Code
        <span
          class="icon-general_link_external"
          aria-hidden="true"
        ></span>
      </a>
      for the occupation.
    </div>

    <input
      :id="inputId"
      :value="fieldValue"
      :name="inputName"
      type="hidden"
    />
  </div>
</template>

<script>
import 'vue-simple-suggest/dist/styles.css';
import BasePicker from './base_picker';

export default BasePicker.extend({
  props: {
    // Required. ID to assign hidden input. Visible input is suffixed with _picker.
    inputId: {
      type: String,
      default: 'vue-simple-picker_picker_no_id',
    },
    // Required. Name to assign hidden input. Visible input is suffixed with _picker.
    inputName: {
      type: String,
      default: 'vue-simple-picker_picker_no_name',
    },
    // Displays just job title in picker.
    textMapping: {
      type: String,
      default: 'label',
    },
    valueMode: {
      validator(val) {
        return ['id', 'label'].includes(val);
      },
      default: 'id',
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    chosen() {
      const { fieldValue } = this;

      this.$emit('change', fieldValue);
    },
  },
  computed: {
    pickerName() {
      const name = this.inputName;
      const p = name.lastIndexOf(']');

      if (p >= 0) {
        return `${name.substr(0, p)}_picker]`;
      }

      return `${name}_picker`;
    },
  },
  methods: {
    apiUrl() {
      return '/lookup/occupation_lookups.json';
    },

    onInputBlur() {
      this.focused = false;
      if (this.chosen != null) return;

      this.$emit('blur');
    },

    processAcquiredLookupData(data) {
      this.matches = data;
      this.loading -= 1;

      // Focused users are given a list of suggestions.
      if (this.focused) { return data; }

      return [];
    },

    setupFromDefaultInput() {
      const {
        inputValue,
        valueMode,
      } = this;

      if (inputValue === '') return;

      let bodyData = null;
      // Similar to base overridden setupFromDefaultInput(), but only uses id mode if supplied value
      // is actually an identifier. Otherwise treats as text.
      const forId = valueMode === 'id' && !Number.isNaN(parseInt(inputValue, 10));

      if (forId) {
        bodyData = { id: inputValue };
      } else {
        this.origText = inputValue;
        this.text = inputValue;
      }

      this.acquireLookupData(bodyData).then(() => {
        // if (forId) {
        //   this.findFirstMatch();
        // } else {
        //   this.findBestMatch();
        // }
      });
    },
  },
});
</script>
