<template>
  <datetime
    v-model="time"
    type="datetime"
    use12-hour="use12-hour"
    :input-id="inputId"
    :hidden-name="inputName"
  />
</template>
<script>
// WARNING: timepicker_input.rb renders as <datepicker> with data-timepicker='true'.
// If you're here from SimpleForm (as: :timepicker), you want that one instead.
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

export default {
  components: {
    datetime: Datetime,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['inputId', 'inputName', 'inputValue'],
  data() {
    return {
      time: this.inputValue,
    };
  },
};
</script>
