<template>
  <div>
    <div class="datepicker__wrapper">
      <input
        :id="inputIdDisplayDate"
        ref="dateInputDisplay"
        v-model.lazy="dateString"
        :aria-required="inputRequired"
        :aria-invalid="!valid"
        :aria-describedby="pickerErrorId"
        :class="inputClasses"
        :placeholder="inputPlaceholder"
        :maxlength="inputMaxlength"
        :disabled="dateEntryDisabled"
        type="text"
        size="10"
        @blur="onBlur"
      />

      <input
        :id="inputId"
        :value="dateTimeToSubmit"
        :name="inputName"
        type="hidden"
      />

      <a
        v-if="!dateEntryDisabled"
        aria-label="Select date"
        class="button button--calendar"
        href="#"
        role="button"
        tabindex="-1"
        aria-hidden="true"
        @click.prevent="showCalendar"
      >
        <span
          class="icon-general_calendar"
        >
        </span>
      </a>
      <a
        v-show="dataToday"
        v-if="!dateEntryDisabled"
        href="#"
        role="button"
        class="button button--minimal button--small button--today"
        @click.prevent="setToday"
      >
        Today
      </a>
      <label
        :for="inputIdDisplayTime"
        class="visually-hidden"
      >Time</label>
      <Select2
        v-show="showTimePicker"
        :id="inputIdDisplayTime"
        ref="timeInputDisplay"
        v-model="timeString"
        :input-classes="inputClasses"
        :maxlength="10"
        :disabled="timeEntryDisabled"
        :placeholder="inputPlaceholder"
        :options="timeEntries"
        :settings="{ tags: false, allowClear: true, dropdownCssClass: 'timepicker' }"
        :aria-required="inputRequired"
        :aria-invalid="!valid"
        :aria-describedby="pickerErrorId"
        wrapper-class="timepicker"
        @change="onBlur"
        @select="onBlur"
        @close="onBlur"
      />
    </div>
    <span
      :id="pickerErrorId"
      class="error"
    >
      {{ errorList.join(', ') }}
    </span>
  </div>
</template>

<script>
import * as chrono from 'chrono-node';
import XDate from 'xdate';

export default {
  /* eslint-disable vue/require-prop-types */
  props: [
    'inputId',
    'inputName',
    'inputRequired',
    'inputValue',
    'inputData',
    'inputAria',
    'inputErrors',
    'inputClasses',
    'inputPlaceholder',
    'inputMaxlength',
    'inputDisabled',
    'inputTimeDisabled',
  ],
  /* eslint-enable vue/require-prop-types */

  data() {
    let data = {
      timeEntries: this.buildTimeEntries(),
      dateString: '',
      timeString: '',
      anyInteraction: false,
      showTimePicker: false,
    };
    data = Object.assign({}, data, { dateString: '', timeString: '' });

    return data;
  },

  computed: {
    inputDataParsed() {
      const { inputData } = this;

      return (typeof inputData === 'string'
        ? JSON.parse(inputData)
        : inputData);
    },

    // Determine if date entry is enabled
    dateEntryDisabled() {
      return this.parseTruthyAttr(this.inputDisabled);
    },

    // Determine if time entry is enabled
    timeEntryDisabled() {
      return this.parseTruthyAttr(this.inputDisabled)
        || this.parseTruthyAttr(this.inputTimeDisabled);
    },

    // Determine whether or not the Today button will display
    dataToday() {
      const dataAttr = this.inputDataParsed;

      if (dataAttr === null || dataAttr.datepicker_today === undefined) {
        return false;
      }

      return dataAttr.datepicker_today;
    },

    // Format the entered date to match the site data format
    dateTimeToSubmit() {
      let formattedString = 'yyyy-MM-dd';

      if (this.manageTime()) {
        formattedString = 'yyyy-MM-dd HH:mm';
      }

      if (this.parsedDate !== null) {
        return this.parsedDate.toString(formattedString);
      }

      return '';
    },

    // Convert text date to date object
    parsedDate() {
      let chronoString = this.dateString;

      if (this.manageTime()) {
        chronoString = `${this.dateString} ${this.timeString}`;
      }
      const parsedDate = chrono.parseDate(chronoString);

      if (parsedDate !== null) {
        return new XDate(parsedDate);
      }

      return parsedDate;
    },

    // Set ID for ariaDescribedBy
    pickerErrorId() {
      return `${this.inputId}_error`;
    },

    // Set ID for the visually displayed text field
    inputIdDisplayDate() {
      return `${this.inputId}_picker`;
    },

    // Set ID for the visually displayed time field
    inputIdDisplayTime() {
      return `${this.inputId}_timepicker`;
    },

    errorList() {
      const suppliedErrors = this.inputErrors;
      const arr = suppliedErrors ? [...suppliedErrors] : [];

      // If errors were explicitly supplied to the component, skip this
      // built-in behavior and use them instead.
      if (suppliedErrors == null && !this.hasValidDate()) {
        arr.push('Invalid Date');
      }

      return arr;
    },

    // Check date for validity
    valid() {
      return this.errorList.length === 0;
    },
  },

  watch: {
    dateTimeToSubmit(changedVal) {
      this.formatDisplayValues();
      this.$forceUpdate();
      this.onInputChange(changedVal);
    },

    valid() {
      if (this.valid) {
        this.$el.parentElement.classList.remove('error');
      } else {
        this.$el.parentElement.classList.add('error');
      }
    },
  },

  mounted() {
    const vm = this;
    const input = this.$refs.dateInputDisplay;
    const dataAttr = this.inputDataParsed;

    if (dataAttr === null || dataAttr.timepicker === undefined) {
      this.showTimePicker = false;
    } else {
      this.showTimePicker = dataAttr.timepicker;
    }

    const opts = Object.assign({
      showOn: 'none',
      constrainInput: false,
      onClose(val) {
        vm.dateString = val;
      },
    }, global.ajlaDatePickerOptions, {
      dateFormat: 'M d, yy', // Format the Calendar will be using from display
    });

    XDate.parsers.push(this.parsePGDateTime);
    $(input).datepicker(opts);

    this.formatInitialDateTime();
    this.ensureTimeValueInDropdown();

    this.shiftLabelFor();
  },

  methods: {
    addTimeEntryTo(arr, hour, meridiem) {
      arr.push(`${hour}:00 ${meridiem}`);
      arr.push(`${hour}:30 ${meridiem}`);
    },

    buildTimeEntries() {
      const arr = [];

      ['AM', 'PM'].forEach((m) => {
        this.addTimeEntryTo(arr, 12, m);

        for (let i = 1; i < 12; i += 1) {
          this.addTimeEntryTo(arr, i, m);
        }
      });

      return arr;
    },

    onInputChange(changedVal) {
      this.$emit('changed', {
        value: changedVal,
        date: this.dateString,
        time: this.timeString,
      });
    },

    parseTruthyAttr(attr) {
      return attr === true || attr === 'true';
    },

    shiftLabelFor() {
      const el = $(`label[for="${this.inputId}"]`);
      const pickerLabel = this.inputIdDisplayDate;
      if (el.length) {
        el.attr('for', pickerLabel);
      }
    },

    manageTime() {
      return (this.showTimePicker && this.timeString !== undefined && this.timeString !== '');
    },

    noTime() {
      return !(this.manageTime());
    },

    noDate() {
      return !this.dateString;
    },

    setToday() {
      const today = new XDate();

      this.dateString = today.toString('MMM d, yyyy');
      this.onBlur();
    },

    ensureTimeValueInDropdown() {
      this.addTimeToEntriesIfMissing(this.timeString);
    },

    addTimeToEntriesIfMissing(timeText) {
      if (!this.showTimePicker) return;
      if (timeText == null || timeText === '') return;

      const { timeEntries } = this;
      if (!timeEntries.find(t => t === timeText)) {
        // Parse date/time for proper comparison.
        // String comparison won't work due to 12:00 AM/PM behavior.
        const suppliedDt = chrono.parseDate(`1-1-1900 ${timeText}`);

        for (let i = 0, len = timeEntries.length; i < len; i += 1) {
          const itemDt = chrono.parseDate(`1-1-1900 ${timeEntries[i]}`);

          if (suppliedDt.valueOf() < itemDt.valueOf()) {
            timeEntries.splice(i, 0, timeText);
            return;
          }
        }

        // Handle times after 11:30 PM.
        timeEntries.push(timeText);
      }
    },

    formatDisplayValues() {
      if (this.showTimePicker) {
        this.formatDisplayDateTime();
      } else {
        this.formatDisplayDate();
      }
    },

    formatDisplayDate() {
      const date = this.dateTimeToSubmit;

      if (date !== undefined && date !== '' && this.valid) {
        const dateStr = new XDate(date);
        this.dateString = dateStr.toString('MMM d, yyyy');
      }
    },

    formatInitialDateTime() {
      const datetime = this.inputValue;

      if (datetime !== undefined && datetime !== '' && this.valid) {
        const dateTimeStr = new XDate(datetime);
        this.dateString = dateTimeStr.toString('MMM d, yyyy');

        if (this.showTimePicker) {
          const formatted = dateTimeStr.toString('h:mm TT');
          this.timeString = formatted;
        }
      }
    },

    formatDisplayDateTime() {
      const datetime = this.dateTimeToSubmit;

      if (datetime !== undefined && datetime !== '' && this.valid) {
        const dateTimeStr = new XDate(datetime);
        this.dateString = dateTimeStr.toString('MMM d, yyyy');

        if (this.manageTime()) {
          const formatted = dateTimeStr.toString('h:mm TT');
          this.timeString = formatted;
        }
      }
    },

    onBlur() {
      if (!this.anyInteraction) {
        this.anyInteraction = true;
      }
    },

    showCalendar() {
      const input = this.$refs.dateInputDisplay;

      if ($(input).datepicker('widget').is(':visible')) {
        $(input).datepicker('hide');
      } else {
        $(input).datepicker('show');
      }
    },

    // This parser resolves a bug with Firefox parsing date/time and loads time
    // without the time zone so we don't get weird issues between different parts
    // of the country
    parsePGDateTime(str) {
      const parts = str.split(' ');

      if (parts.length === 3) {
        // Take off the time zone so it won't get smart and try and adjust
        // for local time.  Without doing this, we end up with a problem where
        // each time a user in a different time zone than the server has a
        // validation error, the time adjusts by the time zone offset between
        // the two locations
        parts.pop();

        // Create the zoneless time stamp
        const datetime = parts.join('T');
        return new XDate(datetime);
      }

      return new XDate(str);
    },

    hasValidDate() {
      if (this.anyInteraction && this.inputRequired && this.noDate() && this.noTime()) {
        return true; // if dateString is blank, it isn't invalid yet
      }

      if (this.dateString) {
        if (this.parsedDate === null) {
          return false;
        }
      }

      return !(this.manageTime() && this.noDate());
    },
  },
};
</script>
